import React from 'react';
import '../App.css';

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 PhoneSale by Gladyreigh Dela Cruz. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
