import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadPayPalScript } from '../utils/paypal'; // Ensure this utility handles PayPal script loading
import '../App.css'; // Import the updated CSS

const CheckoutForm = ({ cart, onOrderComplete }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const navigate = useNavigate();

  // Your PayPal client ID
  const PAYPAL_CLIENT_ID = 'Aaj-l2MMB53_s0tbvdw11G1Cffupc-HBwyjlw2VbY8F6o9TNvWzwSMlwV4BOVxs-RWm2yYTwPzOszbMR';

  useEffect(() => {
    if (paymentMethod === 'paypal' && !paypalLoaded) {
      loadPayPalScript(PAYPAL_CLIENT_ID).then(() => setPaypalLoaded(true));
    }
  }, [paymentMethod, paypalLoaded]);

  useEffect(() => {
    if (paypalLoaded && paymentMethod === 'paypal') {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: convert_aed_usd()
              }
            }]
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            await completeCheckout(details, true);
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout error:', err);
          alert('There was an error processing the payment. Please try again.');
        }
      }).render('#paypal-button-container'); // Ensure this ID matches your CSS
    }
  }, [paypalLoaded, paymentMethod]);

  const handleCheckout = async (event) => {
    event.preventDefault();
    if (cart.length === 0) {
      alert('Your cart is empty. Please add items to the cart before checking out.');
      return;
    }

    if (paymentMethod !== 'paypal') {
      completeCheckout({ id: 'Cash Payment', payer: { name: { given_name: 'Cash Payment' } } }, false);
    } else {
      alert('PayPal / Card Transaction Not Yet Completed');
    }
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const completeCheckout = async (details, isPaypal) => {
    if (isPaypal || window.confirm('Proceed with "cash" payment?')) {
      const orderNumber = generateOrderNumber();
      const items = cart.map(item => `${item.name} - ${item.price.toFixed(2)} AED`).join(', ');
      const message = `
        <html>
        <head><title>Order Confirmation: ${orderNumber}</title></head>
        <body>
          <h1>Order Confirmation</h1>
          <p>Thank you for your order, ${name}.</p>
          <p><strong>Order Number:</strong> ${orderNumber}</p>
          <p><strong>Address:</strong> ${address}</p>
          <p><strong>Mobile:</strong> ${mobile}</p>
          <p><strong>Items:</strong> ${items}</p>
          <p><strong>Transaction Method:</strong> ${details.id}</p>
        </body>
        </html>
      `;

      try {
        const response = await sendEmail(email, message, orderNumber);
        if (response.ok) {
          alert(`Order placed! Email sent. Your order number is ${orderNumber}.`);
          onOrderComplete(); // Clear cart and navigate back
          navigate('/');
        } else {
          alert('There was an error placing the order.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('There was an error placing the order. Please try again later.');
      }
    }
  };

  const generateOrderNumber = (length = 6) => {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const sendEmail = async (to, message, orderNumber) => {
    const smtpEndpoint = 'https://api.smtp2go.com/v3/email/send';  // Verify the correct endpoint
    const apiKey = 'api-AF4BE98C43FF422EAC600E6C9CF3C5C8';  // Replace with your actual API key
    const fromName = 'PhoneSale';
    const fromEmail = 'support@phonesale.org';

    const body = JSON.stringify({
      api_key: apiKey,
      to: [to, 'grey@phonesale.org'],
      sender: `${fromName} <${fromEmail}>`,
      subject: `Order Confirmation: ${orderNumber}`,
      html_body: message,
    });

    const init = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body,
    };

    return fetch(smtpEndpoint, init);
  };

  const convert_aed_usd = () => {
    return (cart.reduce((total, product) => total + parseFloat(product.price), 0) / 3.63).toFixed(2);
  };

  return (
    <div className="checkout-form">
      <h2>Checkout</h2>
      <form onSubmit={handleCheckout}>
        <div>
          <label>
            Name:
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </label>
        </div>
        <div>
          <label>
            Email:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </label>
        </div>
        <div>
          <label>
            Address:
            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
          </label>
        </div>
        <div>
          <label>
            Mobile:
            <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
          </label>
        </div>
        <div>
          <label>
            Payment Method:
            <select value={paymentMethod} onChange={handlePaymentMethodChange}>
              <option value="cash">Cash</option>
              <option value="paypal">PayPal / Card</option>
            </select>
          </label>
        </div>
        {paymentMethod === 'paypal' && (
          <div id="paypal-button-container"></div>
        )}
        <button type="submit">Submit Order</button>
      </form>
      <button className="back-to-cart-button" onClick={() => navigate('/cart')}>Back to Cart</button>
    </div>
  );
};

export default CheckoutForm;
