import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../App.css';

function Products({ addToCart }) {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('low-to-high');

  useEffect(() => {
    fetchProducts();
  }, []);

  async function fetchProducts() {
    try {
      const response = await fetch('https://rees.greygladyreigh.workers.dev/products');
      const result = await response.json();
      const fetchedProducts = result.data.map(product => ({
        name: product.name,
        price: product.price,
        image: product.image_url,
      }));
      setProducts(fetchedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }

  function handleSearch(e) {
    setSearchQuery(e.target.value);
  }

  function handleSort(e) {
    setSortOption(e.target.value);
  }

  function filteredProducts() {
    return products
      .filter(product => product.name.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => (sortOption === 'low-to-high' ? a.price - b.price : b.price - a.price));
  }

  return (
    <section id="products">
      <h2>Products</h2>
      <div className="search-sort-container">
        <div id="search-bar-container">
          <input
            type="text"
            id="search-bar"
            placeholder="Search products..."
            aria-label="Search products"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <select id="sort-options" aria-label="Sort products" value={sortOption} onChange={handleSort}>
          <option value="default">Sort by</option>
          <option value="low-to-high">Price: Low to High</option>
          <option value="high-to-low">Price: High to Low</option>
        </select>
      </div>
      <div className="product-list" id="product-list">
        {filteredProducts().map(product => (
          <div className="product" key={product.name}>
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <div className="price-container">
              <p className="price">{product.price.toFixed(2)} AED</p>
            </div>
            <button onClick={() => addToCart(product)}>Add to Cart</button>
          </div>
        ))}
      </div>
      <div className="go-to-cart">
        <Link to="/cart" className="go-to-cart-button">
          Go to Shopping Cart
        </Link>
      </div>
    </section>
  );
}

export default Products;
