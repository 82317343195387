// src/components/AboutUs.js

import React from 'react';
import './AboutUs.css'; // Import your CSS module here

const AboutUs = () => {
  return (
    <main>
      <a href="/" className="back-buttonnes">Back to Shop</a>
      <section id="about">
        <div className="container">
          <h1>About PhoneSale</h1>
          <p>Welcome to PhoneSale! Founded by Gladyreigh Dela Cruz in 2024, PhoneSale is dedicated to offering the finest gadgets at reasonable prices. Our mission is to provide solutions that make owning a high-quality phone accessible and affordable for everyone, ensuring pure satisfaction with each purchase.</p>

          <div className="founder">
            <img src="../images/founder.jpg" alt="Gladyreigh Dela Cruz" />
            <div className="info">
              <h2>Meet the Founder</h2>
              <p>Gladyreigh Dela Cruz envisioned PhoneSale to bridge the gap between high-quality technology and affordability. With a focus on customer satisfaction and innovation, Gladyreigh created PhoneSale to help people find the perfect phone without overspending.</p>
            </div>
          </div>

          <div className="team">
            <h2>Meet Our Team</h2>
            <div className="team-members">
              <div className="team-member">
                <h3>Gladyreigh Dela Cruz</h3>
                <p>Head Manager & Web Developer</p>
                <p>Gladyreigh excels as our Head Manager and Web Developer, overseeing project execution and ensuring seamless integration of our business strategies.</p>
              </div>
              <div className="team-member">
                <h3>Kenji Visorro</h3>
                <p>Graphics Design & Logo Design</p>
                <p>Kenji ensures professional and creative designs for our graphics and logos, bringing a touch of elegance and innovation to our visual identity.</p>
              </div>
            </div>
          </div>

          <section id="contactp">
            <div className="container">
              <h2>Join Us</h2>
              <p>Are you passionate about making a difference? We are in the early stages of our journey and are welcoming enthusiastic individuals to voluntarily join our team. If you want to contribute your skills and be part of something exciting, we invite you to be a part of our growing team.</p>
              <p>Email: <a href="mailto:support@phonesale.org">support@phonesale.org</a></p>
              <p>Phone: <a href="tel:+971566053401">+971 56 605 3401</a></p>
            </div>
          </section>
        </div>
      </section>
      <a href="/" className="back-buttonnes">Back to Shop</a>
    </main>
  );
};

export default AboutUs;
