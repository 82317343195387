import React from 'react';
import './ContactUs.css'; // Import your CSS file here

const ContactUs = () => {
  return (
    <main>
      <header className="header">
        <h1>Contact Us</h1>
        <a href="/" className="back-button">← Back to Home</a>
      </header>

      <section id="contacta">
        <div className="container">
          <h2>Get in Touch</h2>
          <p>If you have any questions or need support, feel free to reach out to us. We're here to help!</p>

          <div className="contact-info">
            <div className="contact-item">
              <h3>Email</h3>
              <p><a href="mailto:support@phonesale.org">support@phonesale.org</a></p>
            </div>
            <div className="contact-item">
              <h3>Phone</h3>
              <p><a href="tel:+971566053401">+971 56 605 3401</a></p>
            </div>
            <div className="contact-item">
              <h3>WhatsApp</h3>
              <p><a href="https://api.whatsapp.com/send?phone=+971566053401&text=Hello,%20I%20have%20an%20enquiry." target="_blank" rel="noopener noreferrer">+971 56 605 3401</a></p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactUs;
