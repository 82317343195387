import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function Cart({ cart, removeFromCart }) {
  const navigate = useNavigate(); // Hook for programmatic navigation

  function total() {
    return cart.reduce((total, product) => total + product.price, 0).toFixed(2);
  }

  const handleCheckout = () => {
    if (cart.length === 0) {
      alert('Your cart is empty. Add items to the cart before proceeding to checkout.');
    } else {
      navigate('/checkout');
    }
  };

  return (
    <section id="cart">
      <h2>Shopping Cart</h2>
      <div id="cart-items">
        {cart.length > 0 ? (
          cart.map((product, index) => (
            <div className="cart-item" key={index}>
              <p>{product.name} - {product.price.toFixed(2)} AED</p>
              <button onClick={() => removeFromCart(index)}>Remove</button>
            </div>
          ))
        ) : (
          <p>Your cart is empty</p>
        )}
      </div>
      <p id="total">Total: {total()} AED</p>
      <div className="button-container">
        <button id="checkout" onClick={handleCheckout}>Checkout</button>
        <button id="back-to-products" onClick={() => navigate('/')}>Back to Products</button>
      </div>
    </section>
  );
}

export default Cart;
