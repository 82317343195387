import React, { useState } from 'react';
import styles from './RepairForm.module.css'; // Import your CSS module

const RepairForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneModel, setPhoneModel] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const emailMessage = `
      <h2>Repair Request</h2>
      <p>Dear ${name},</p>
      <p>Thank you for reaching out. We have received your request. We will contact you shortly about your repair request.</p>
      <p><strong>Phone Model:</strong> ${phoneModel}</p>
      <p><strong>Mobile Number:</strong> ${mobileNumber}</p>
      <p><strong>Address:</strong> ${address}</p>
      <p><strong>Message:</strong> ${message}</p>
    `;

    try {
      const response = await sendEmail(email, emailMessage, phoneModel);
      if (response.ok) {
        alert('Thank you for reaching out. We have received your request. We will contact you shortly about your repair request');
      } else {
        alert('Failed to send request.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred.');
    }
  };

  const sendEmail = async (to, message, phoneModel) => {
    const smtpEndpoint = 'https://api.smtp2go.com/v3/email/send';
    const apiKey = 'api-AF4BE98C43FF422EAC600E6C9CF3C5C8';
    const fromName = 'PhoneSale';
    const fromEmail = 'support@phonesale.org';

    const body = JSON.stringify({
      api_key: apiKey,
      to: [to, 'grey@phonesale.org'],
      sender: `${fromName} <${fromEmail}>`,
      subject: 'Contact Request: ' + phoneModel,
      html_body: message,
    });

    const init = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body,
    };

    const response = await fetch(smtpEndpoint, init);
    return response;
  };

  return (
    <main className={styles.main}>
      <div className={styles.notice}>
        At PhoneSale Repair, we ensure high-quality repairs at reasonable and affordable prices. Simply fill out the form below, and we will reach out to you shortly regarding your device.
      </div>
      <div id="contact-form" className={styles.contactForm}>
        <h2>Repair Request Form</h2>
        <form id="repairForm" onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

          <label htmlFor="phoneModel">Phone Model:</label>
          <input type="text" id="phoneModel" value={phoneModel} onChange={(e) => setPhoneModel(e.target.value)} required />

          <label htmlFor="mobileNumber">Mobile Number:</label>
          <input type="text" id="mobileNumber" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required />

          <label htmlFor="address">Address:</label>
          <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />

          <label htmlFor="message">What do you think is the issue of your device? (e.g., Battery, LCD Screen):</label>
          <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} required />

          <div id="buttonContainer" className={styles.buttonContainer}>
            <button type="submit">Send Request</button>
            <button type="button" id="backButton" className={styles.backButton} onClick={() => window.location.href = '/'}>Back</button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default RepairForm;
